import React, { useState } from 'react';
import { Container, Nav } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { AiOutlineHome, AiOutlineTable, AiOutlineFileText } from 'react-icons/ai'; // Import icons
import FilterModal from './FilterModal';

import './JTSide.css';

const JTSide = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  const [showFilterModal, setShowFilterModal] = useState(false);
  const toggleFilterModal = () => {
    setShowFilterModal(!showFilterModal);
  };

  return (
    <Container fluid>
      <div className="sidebar">
        <Nav defaultActiveKey="/" className="flex-column">
          {/* Home Navigation */}
          <div className="nav-link-container">
            <Nav.Link href="/projects_list" className={currentPath === '/projects_list' ? 'nav-link active' : 'nav-link'}>
              <AiOutlineHome /> Home
            </Nav.Link>
          </div>

          {/* Create New Navigation */}
          {/* <div className="nav-link-container">
            <Nav.Link href="/create-new" className={currentPath === '/create-new' ? 'nav-link active' : 'nav-link'}>
              <AiOutlineTable />
              <br />
              Create New
            </Nav.Link>
          </div> */}

          {/* Reports Navigation */}
          <div className="nav-link-container">
            <Nav.Link href="/project_summary" className={currentPath === '/reports' ? 'nav-link active' : 'nav-link'}>
              <AiOutlineFileText />
              <br />
              Reports
            </Nav.Link>
          </div>
        </Nav>
      </div>
      <br />
      <FilterModal showModal={showFilterModal} setShowModal={setShowFilterModal} />
    </Container>
  );
};

export default JTSide;
