import React, { useState, useEffect } from "react";
import { Table, Button, Spinner, Toast, ToastContainer } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "../Components/JTProjectList.css"

const JTProjectList = () => {
  const [projectNames, setProjectNames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProjectNames = async () => {
      setLoading(true);
      setError("");

      try {
        const response = await fetch("https://4sightoperations.in/api/segments/", {
          method: "GET",
          headers: {
            Authorization: `Token ${localStorage.getItem("Token")}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch project names");
        }

        const data = await response.json();
        console.log("Fetched data:", data);

        const formattedProjectNames = data.map((project) => ({
          projectName: project.project_name,
          jobNumber: project.job_number,
          unit: project.unit,
          year: project.year,
          month: project.month,
          segmentNumber: project.no_of_segments,
          segmentName: project.segment_name || "-",
          client: project.client || "-",
          completedMonth: project.completed_month || "-",
          achievedSampleSize: project.achieved_sample_size || "-",
          actualDateOfFinalOutput: project.actual_date_of_final_output
            ? moment(project.actual_date_of_final_output).format("DD/MM/YYYY")
            : "-",
          actualDateOfLink: project.actual_date_of_link
            ? moment(project.actual_date_of_link).format("DD/MM/YYYY")
            : "-",
          actualSampleSize: project.actual_sample_size || "-",
          advancedAnalytics: project.advanced_analytics || "-",
          analysisGroupHead: project.analysis_group_head || "-",
          apReceivedDate: project.ap_received_date
            ? moment(project.ap_received_date).format("DD/MM/YYYY")
            : "-",
          archiveTaken: project.archive_taken || "-",
          backupFolderPath: project.backup_folder_path || "-",
          backupTaken: project.backup_taken || "-",
          checkListFollowed: project.check_list_followed || "-",
          contactPersonForDp: project.contact_person_for_dp || "-",
          contactPersonForScripting: project.contact_person_for_scripting || "-",
          contactPersonInResearch: project.contact_person_in_research || "-",
          dataCorrectionDone: project.data_correction_done || "-",
          dateOfAwn: project.date_of_awn
            ? moment(project.date_of_awn).format("DD/MM/YYYY")
            : "-",
          dateOfRevisedAwn: project.date_of_revised_awn
            ? moment(project.date_of_revised_awn).format("DD/MM/YYYY")
            : "-",
          dpPlatform: project.dp_platform || "-",
          expectedDateOfFinalOutput: project.expected_date_of_final_output
            ? moment(project.expected_date_of_final_output).format("DD/MM/YYYY")
            : "-",
          expectedDateOfLink: project.expected_date_of_link
            ? moment(project.expected_date_of_link).format("DD/MM/YYYY")
            : "-",
          feedbackSurveyDone: project.feedback_survey_done || "-",
          finalAwnDate: project.final_awn_date
            ? moment(project.final_awn_date).format("DD/MM/YYYY")
            : "-",
          finalProcessedSamples: project.final_processed_samples || "-",
          fwEndDate: project.fw_end_date
            ? moment(project.fw_end_date).format("DD/MM/YYYY")
            : "-",
          fwStartDate: project.fw_start_date
            ? moment(project.fw_start_date).format("DD/MM/YYYY")
            : "-",
          lastSegmentNumber: project.last_segment_number || "-",
          latestToplineDate: project.latest_topline_date
            ? moment(project.latest_topline_date).format("DD/MM/YYYY")
            : "-",
          loi: project.loi || "-",
          noOfSegments: project.no_of_segments || "-",
          noOfTopLines: project.no_of_top_lines || "-",
          normsDone: project.norms_done || "-",
          numberOfResponses: project.number_of_responses || "-",
          oeCodingDone: project.oe_coding_done || "-",
          oeCosting: project.oe_costing || "-",
          projectClassification: project.project_classification || "-",
          qnrReceivedDate: project.qnr_received_date
            ? moment(project.qnr_received_date).format("DD/MM/YYYY")
            : "-",
          remarks: project.remarks || "-",
          researchGroupHead: project.research_group_head || "-",
          segmentName: project.segment_name || "-",
          status: project.status || "-",
          surveyPlatform: project.survey_platform || "-",
        }));
        

        setProjectNames(formattedProjectNames);
        console.log(`Fetched ${formattedProjectNames.length} projects.`);
      } catch (error) {
        console.error("Error fetching projects:", error);
        setError("An error occurred while fetching projects.");
      } finally {
        setLoading(false);
      }
    };

    fetchProjectNames();
  }, []);

  const handleEdit = (jobNumber) => {
    navigate("/edit", {
      state: {
        jobNumber,
        editMode: true,
      },
    });
  };

  const handleView = (project) => {
    navigate("/project_detailed_view", { state: { project } });
  };

  const handleCreateNew = () => {
    navigate("/create-new");
  };

  return (
    <div>
      <div className="tableEffect" style={{ width: "95%", margin: "auto" }}>
        {loading ? (
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <Spinner animation="border" variant="primary" />
            <p>Loading projects...</p>
          </div>
        ) : error ? (
          <div style={{ textAlign: "center", color: "red" }}>
            <p>{error}</p>
          </div>
        ) : projectNames.length === 0 ? (
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <p>No projects found.</p>
          </div>
        ) : (
          <div style={{ overflowX: "auto" }}>
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <Button variant="primary" onClick={handleCreateNew}>
                +
              </Button>
            </div>
            <br />
            <Table bordered hover>
              <thead>
                <tr>
                  <th>UNIT</th>
                  <th>YEAR</th>
                  <th>MONTH</th>
                  <th>PROJECT NAME</th>
                  <th>JOB NUMBER</th>
                  <th>SEGMENT NUMBER</th>
                  <th>SEGMENT NAME</th>
                  <th>CONTACT PERSON (Research)</th>
                  <th>CONTACT PERSON (Script)</th>
                  <th>CONTACT PERSON (DP)</th>
                  <th>EXPECTED FINAL LINK</th>
                  <th>ACTUAL FINAL LINK </th>
                  
                  <th>EXPECTED FINAL OUTPUT</th>
                  <th>ACTUAL FINAL OUTPUT</th>
                  
                  <th>STATUS</th>
                  <th>VIEW</th>
                  <th>EDIT</th>
                </tr>
              </thead>
              <tbody>
                {projectNames.map((project, index) => (
                  <tr key={index}>
                    <td>{project.unit}</td>
                    <td>{project.year}</td>
                    <td>{project.month}</td>
                    <td>{project.projectName}</td>
                    <td>{project.jobNumber}</td> 
                    <td>{project.segmentNumber}</td>
                    <td>{project.segmentName}</td>
                    <td>{project.contactPersonInResearch}</td>
                    <td>{project.contactPersonForScripting}</td>
                    <td>{project.contactPersonForDp}</td>
                    <td>{project.expectedDateOfFinalLink}</td>
                    <td>{project.actualDateOfFinalLink}</td>
                    
                    
                    <td>{project.expectedDateOfFinalOutput}</td>
                    <td>{project.actualDateOfFinalOutput}</td>
                    <td>{project.status}</td>
                    <td>
                      <FontAwesomeIcon
                        icon={faEye}
                        style={{ cursor: "pointer", color: "#3c979f" }}
                        onClick={() => handleView(project)}
                      />
                    </td>
                    <td>
                      <FontAwesomeIcon
                        icon={faEdit}
                        style={{ cursor: "pointer", color: "#3c979f" }}
                        onClick={() => handleEdit(project.jobNumber)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
      </div>

      {/* Success Toast */}
      <ToastContainer position="bottom-end" className="p-3">
        <Toast
          onClose={() => setShowSuccessToast(false)}
          show={showSuccessToast}
          delay={3000}
          autohide
          bg="success"
        >
          <Toast.Header>
            <strong className="me-auto">Success</strong>
          </Toast.Header>
          <Toast.Body>{successMessage}</Toast.Body>
        </Toast>

        {/* Error Toast */}
        {error && (
          <Toast
            onClose={() => setError("")}
            show={!!error}
            delay={5000}
            autohide
            bg="danger"
          >
            <Toast.Header>
              <strong className="me-auto">Error</strong>
            </Toast.Header>
            <Toast.Body>{error}</Toast.Body>
          </Toast>
        )}
      </ToastContainer>
    </div>
  );
};

export default JTProjectList;
