import React, { useState, useEffect, useRef  } from 'react';
import axios from 'axios';
import { Form, Button, Alert, Container, Row, Col, Spinner, ListGroup } from 'react-bootstrap';

const DriverDataUpload = ({ setColumns, setFileId, setBinaryColumns }) => {
    const [file, setFile] = useState(null);
    const [uploadedFiles, setUploadedFiles] = useState([]); // Track uploaded files
    const [errorMessage, setErrorMessage] = useState('');
    const [isUploading, setIsUploading] = useState(false); // Track upload progress
    const [successMessage, setSuccessMessage] = useState(''); // Display success messages
    const fileInputRef = useRef(null); // 
    // Fetch files on component mount
    useEffect(() => {
        setErrorMessage('')
        fetchUploadedFiles();
    }, []);

    const fetchUploadedFiles = async () => {
       
        try {
            const response = await axios.get('https://4sightoperations.in/drivers/list_files/', {
                headers: {
                    Authorization: `Token ${localStorage.getItem('Token')}`,
                },
            });
            const files = response.data.map((file) => ({
                name: file.name,
                id: file.name, // Use the file name as an identifier
                columns: [], // Populate this when loading the file
                binaryColumns: [], // Populate this when loading the file
            }));
            setUploadedFiles(files);
        } catch (error) {
            console.error('Error fetching files:', error);
            //setErrorMessage('Failed to fetch uploaded files.');
        }
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleFileUpload = async () => {
        setSuccessMessage('');
        if (!file) {
            setErrorMessage('Please select a file to upload.');
            return;
        }
    
        const formData = new FormData();
        formData.append('file', file);
    
        setIsUploading(true); // Start spinner
        try {
            const response = await axios.post('https://4sightoperations.in/drivers/upload/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Token ${localStorage.getItem('Token')}`,
                },
            });
    
            const fileDetails = {
                name: file.name,
                id: response.data.file_id,
                columns: response.data.columns || [],
                binaryColumns: response.data.binary_columns || [],
            };
    
            setUploadedFiles((prevFiles) => [...prevFiles, fileDetails]); // Add to the list
            setColumns(fileDetails.columns);
            setBinaryColumns(fileDetails.binaryColumns);
            setFileId(fileDetails.id);
            setErrorMessage('');
            setFile(null)
            setSuccessMessage('File uploaded successfully.');
            // Clear the file input
            if (fileInputRef.current) {
                fileInputRef.current.value = ''; // Reset the file input value
            }
        } catch (error) {
            console.error('Upload Error:', error.response || error.message);
            setErrorMessage('Error uploading file. Please try again.');
        } finally {
            setIsUploading(false); // Stop spinner
        }
    };
    
    const handleLoadFile = async (fileId) => {
        //alert('hi')
        //setFile(null)
        setErrorMessage('')
        const selectedFile = uploadedFiles.find((file) => file.id === fileId);
        if (selectedFile) {
            if (!selectedFile.columns.length) {
                // Fetch columns from the server if not already loaded
                try {
                    const response = await axios.get(`https://4sightoperations.in/drivers/file_columns/${fileId}/`, {
                        headers: {
                            Authorization: `Token ${localStorage.getItem('Token')}`,
                        },
                    });
                    selectedFile.columns = response.data.columns;
                    selectedFile.binaryColumns = response.data.binary_columns;
                } catch (error) {
                    console.error('Error fetching file columns:', error);
                    setErrorMessage('Failed to load file columns.');
                    return;
                }
            }
    
            // Set state with the loaded file's columns
            setColumns(selectedFile.columns);
            setBinaryColumns(selectedFile.binaryColumns);
            setFileId(selectedFile.id);
            setSuccessMessage(`File "${selectedFile.name}" loaded successfully.`);
        } else {
            setErrorMessage('File not found in the list.');
        }
    };
    

    return (
        <Container fluid>
            <Row>
                {/* Sidebar */}
                

                {/* Main Content */}
                <Col md={9} className="p-3  ps-5">
                    <h3 className="text-center mb-4">Upload SPSS Data for Driver Analysis</h3>
                    <Form >
                        <Form.Group controlId="fileUpload">
                            <Form.Label className="custom-label">Select SPSS File:</Form.Label>
                            <Form.Control
                                type="file"
                                onChange={handleFileChange}
                                accept=".sav"
                                ref={fileInputRef}
                            />
                        </Form.Group>
                        <Button
                            variant="primary"
                            className="mt-3"
                            onClick={handleFileUpload}
                            disabled={isUploading} // Disable button during upload
                        >
                            {isUploading ? (
                                <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />{' '}
                                    Uploading...
                                </>
                            ) : (
                                'Upload'
                            )}
                        </Button>
                    </Form>
                    {errorMessage && (
                        <Alert variant="danger" className="mt-3">
                            {errorMessage}
                        </Alert>
                    )}
                    {successMessage && (
                        <Alert variant="success" className="mt-3">
                            {successMessage}
                        </Alert>
                    )}
                </Col>
                <Col md={3} className="bg-light p-3">
                    <h5>Uploaded Files</h5>
                    {uploadedFiles.length > 0 ? (
                        <ListGroup>
                            {uploadedFiles.map((file, index) => (
                                <ListGroup.Item
                                    key={file.id}
                                    action
                                    onClick={() => handleLoadFile(file.id)}
                                >
                                    {file.name}
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    ) : (
                        <p>No files uploaded yet.</p>
                    )}
                </Col>
            </Row>
        </Container>
    );
};

export default DriverDataUpload;
