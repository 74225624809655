import React, { useState, useEffect } from "react";
import { Form, Spinner, Alert, Table, Row, Col } from "react-bootstrap";
import axios from "axios";

const JTStartMonthSummary = () => {
  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);
  const [summaryData, setSummaryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");

  // Fetch distinct months and years on mount
  useEffect(() => {
    const fetchFilters = async () => {
      try {
        setLoading(true);

        // Fetch distinct start months
        const monthsResponse = await axios.get(
          "https://4sightoperations.in/api/segments/distinct_start_months/",
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("Token")}`,
              "Content-Type": "application/json",
            },
          }
        );
        setMonths(monthsResponse.data.filter(Boolean));

        // Fetch distinct start years
        const yearsResponse = await axios.get(
          "https://4sightoperations.in/api/segments/distinct_start_years/",
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("Token")}`,
              "Content-Type": "application/json",
            },
          }
        );
        setYears(yearsResponse.data.filter(Boolean));
      } catch (err) {
        setError("Failed to fetch filter data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchFilters();
  }, []);

  // Fetch start month summary data
  const fetchSummaryData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        "https://4sightoperations.in/api/segments/start_month_summary/",
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("Token")}`,
            "Content-Type": "application/json",
          },
          params: {
            start_month: selectedMonth || undefined,
            start_year: selectedYear || undefined,
          },
        }
      );
      const yearsResponse = await axios.get(
        "https://4sightoperations.in/api/segments/distinct_start_years/",
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("Token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      
      setSummaryData(response.data);
      setError(null);
    } catch (err) {
      setError("Failed to fetch summary data. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = () => {
    if (selectedMonth || selectedYear) {
      fetchSummaryData();
    } else {
      setSummaryData([]);
    }
  };

  return (
    <>
      {loading && (
        <div className="text-center">
          <Spinner animation="border" variant="primary" />
          <p>Loading...</p>
        </div>
      )}
      {error && (
        <Alert variant="danger" className="text-center">
          {error}
        </Alert>
      )}
      {!loading && !error && (
        <>
          <Row>
            <Col>
              <Form.Group controlId="yearSelect" className="mb-4">
                <Form.Label>Select Start Year</Form.Label>
                <Form.Control
                  as="select"
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}
                >
                  <option value="">-- Select Year --</option>
                  {years.map((year, index) => (
                    <option key={index} value={year}>
                      {year}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="monthSelect" className="mb-4">
                <Form.Label>Select Start Month</Form.Label>
                <Form.Control
                  as="select"
                  value={selectedMonth}
                  onChange={(e) => setSelectedMonth(e.target.value)}
                >
                  <option value="">-- Select Month --</option>
                  {months.map((month, index) => (
                    <option key={index} value={month}>
                      {month}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <button onClick={handleFilterChange} className="btn btn-primary mb-4">
            Apply Filters
          </button>

          {summaryData.length > 0 ? (
            <>
              <h4>Contact Persons</h4>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>In Research</th>
                    <th>For Scripting</th>
                    <th>For DP</th>
                    <th>Project Names</th>
                  </tr>
                </thead>
                <tbody>
                  {summaryData.map((item, index) => (
                    <tr key={index}>
                      <td>{item.contact_person_in_research || "N/A"}</td>
                      <td>{item.contact_person_for_scripting || "N/A"}</td>
                      <td>{item.contact_person_for_dp || "N/A"}</td>
                      <td>{item.project_name || "N/A"}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
             

<h4>Status Summary</h4>
<Table striped bordered hover>
  <thead>
    <tr>
      <th>Status</th>
      <th>Count</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Open</td>
      <td>
        {summaryData.reduce(
          (sum, item) => sum + (item.status_Open_count || 0),
          0
        )}
      </td>
    </tr>
    <tr>
      <td>Closed</td>
      <td>
        {summaryData.reduce(
          (sum, item) => sum + (item.status_Closed_count || 0),
          0
        )}
      </td>
    </tr>
  </tbody>
</Table>


              <h4>Survey Platform Summary</h4>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Project Name</th>
                    {Object.keys(summaryData[0] || {})
                      .filter((key) => key.startsWith("survey_platform_"))
                      .map((key) => (
                        <th key={key}>
                          {key.replace("survey_platform_", "").replace(/_status_.*/, "")}
                        </th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {summaryData.map((item, index) => (
                    <tr key={index}>
                      <td>{item.project_name || "N/A"}</td>
                      {Object.keys(summaryData[0] || {})
                        .filter((key) => key.startsWith("survey_platform_"))
                        .map((key) => (
                          <td key={key}>{item[key] || 0}</td>
                        ))}
                    </tr>
                  ))}
                </tbody>
              </Table>

              <h4>DP Platform Summary</h4>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Project Name</th>
                    {Object.keys(summaryData[0] || {})
                      .filter((key) => key.startsWith("dp_platform_"))
                      .map((key) => (
                        <th key={key}>
                          {key.replace("dp_platform_", "").replace(/_status_.*/, "")}
                        </th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {summaryData.map((item, index) => (
                    <tr key={index}>
                      <td>{item.project_name || "N/A"}</td>
                      {Object.keys(summaryData[0] || {})
                        .filter((key) => key.startsWith("dp_platform_"))
                        .map((key) => (
                          <td key={key}>{item[key] || 0}</td>
                        ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          ) : (
            <Alert variant="info">No data available for the selected filters.</Alert>
          )}
        </>
      )}
    </>
  );
};

export default JTStartMonthSummary;
