import React, { useContext } from 'react';

import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

import './NavigationBar.css';
import logo from '../Components/4sightlogo.png';
import AuthContext from '../Components/AuthContext';

const NavigationBar = () => {
  const { isLoggedIn, username, handleLogout } = useContext(AuthContext); // Access context values
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  const onLogoutClick = () => {
    handleLogout(); // Use handleLogout from context
    navigate('/');
  };

  return (
    <div>
      <Navbar style={{ backgroundColor: "#f0f8ff" }}>
        <Container fluid>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav.Item style={{ width: '200%', height: '75px' }}>
            <Link to="/">
                <img
                  src={logo}
                  alt="4sightlogo"
                  className="logo"
                  style={{ width: '100px', height: '90px', transform: 'scale(1.5)' }}
                />
              </Link>
            </Nav.Item>
            <div style={{ color: 'black', whiteSpace: 'nowrap', maxWidth: 'fit-content' }}><h2>4SIGHT OPERATIONS</h2></div>
            <Nav style={{ display: 'flex', justifyContent: 'end', alignItems: 'end', marginLeft: '450px',paddingRight: '100px', }} className="me-auto">
              <NavDropdown
                title={isLoggedIn ? <span className="username">Hi - {username}</span> : "Login"}
                id="basic-nav-dropdown"
                className="custom-dropdown"
              >
                {!isLoggedIn && (
                  <NavDropdown.Item as={Link} to="/login">Login</NavDropdown.Item>
                )}
                <NavDropdown.Item href="/profile">Profile</NavDropdown.Item>
                {/* <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item> */}
                <NavDropdown.Divider />
                {isLoggedIn && (
                  <NavDropdown.Item onClick={onLogoutClick}>Logout</NavDropdown.Item>
                )}
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default NavigationBar;
