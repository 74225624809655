import React, { useState } from 'react';
import { Container, Row, Col, Button, Form, Alert } from 'react-bootstrap';
import { IoIosArrowForward } from 'react-icons/io';
import { nanoid } from 'nanoid';
import './Survey.css';

const SurveyDetails = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [researcherInCharge, setResearcherInCharge] = useState('');
  const [projectName, setProjectName] = useState('');
  const [jobNumber, setJobNumber] = useState('');
  const [year, setYear] = useState('');
  const [month, setMonth] = useState('');
  const [inCharge, setInCharge] = useState('');
  const [inCharge1, setInCharge1] = useState('');
  const [surveyId, setSurveyId] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlert1, setShowAlert1] = useState(false);
  const [yearError, setYearError] = useState(''); // Error state for year validation

  const currentYear = new Date().getFullYear();

  const handleYearChange = (e) => {
    const inputYear = e.target.value;
    
    // Allow only numbers and limit input to 4 digits
    if (/^\d{0,4}$/.test(inputYear)) {
      setYear(inputYear);

      // Check if the entered year is greater than or equal to the current year
      if (inputYear && parseInt(inputYear, 10) < currentYear) {
        setYearError(`Year must be ${currentYear} or later.`);
      } else {
        setYearError('');
      }
    }
  };

  const handleFormSubmit = async () => {
    if (projectName && jobNumber && researcherInCharge && year && !yearError && month && inCharge && inCharge1) {
      setShowAlert(false);
      const formData = {
        researcher_in_charge: researcherInCharge,
        project_name: projectName,
        job_number: jobNumber,
        year,
        month,
        dp_or_scripting: "Both",
        scripting_in_charge: inCharge,
        dp_in_charge: inCharge1,
      };
      try {
        const response = await fetch('https://4sightoperations.in/survey/create-survey/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${localStorage.getItem('Token')}`,
          },
          body: JSON.stringify(formData),
        });
        if (response.ok) {
          const data = await response.json();
          setSurveyId(data.survey_id);
          setCurrentStep(1);
        } else {
          console.error('Failed to create survey:', await response.json());
        }
      } catch (error) {
        console.error('Error:', error);
      }
    } else {
      setShowAlert(true);
    }
  };

  const handleCopyLink = () => {
    const link = `${window.location.origin}/satisfactionsurvey/${surveyId}`;
    navigator.clipboard.writeText(link);
    setShowAlert1(true);
    setTimeout(() => setShowAlert1(false), 2000);
  };

  return (
    <Container className="my-4">
      {currentStep === 0 && (
        <>
          {showAlert && (
            <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>
              Please fill in all required fields!
            </Alert>
          )}
          <div className="form-container p-4 rounded shadow-sm" style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
            <h5 className="mb-4">Survey Details</h5>
            <Form>
              {/* Researcher In Charge */}
              <Form.Group as={Row} controlId="formResearcher">
                <Form.Label column sm="4">Researcher In Charge:</Form.Label>
                <Col sm="8">
                  <Form.Control as="select" value={researcherInCharge} onChange={(e) => setResearcherInCharge(e.target.value)} >
                  <option value hidden>Select a Researcher</option>
		                          <option value="Ananya">Ananya</option>
		                          <option value="Aruna">Aruna</option>
		                          <option value="Leena">Leena</option>
		                          <option value="Manoj K">Manoj K</option>
		                          <option value="Rita">Rita</option>
		                          <option value="Sonal">Sonal</option>
		                          <option value="Elias">Elias</option>
		                          <option value="Tarek">Tarek</option>
		                          <option value="Ismail">Ismail</option>
		                          <option value="Nour">Nour</option>
		                          <option value="Muhammed">Muhammed</option>
		                          <option value="Ashish">Ashish</option>
		                          <option value="Manoj I">Manoj I</option>
		                          <option value="Krati">Krati</option>
		                          <option value="Kunal M">Kunal M</option>
		                          <option value="Vinivaa">Vinivaa</option>
		                          <option value="Priya">Priya</option>
		                          <option value="Sharanya">Sharanya</option>
		                          <option value="Pulkit">Pulkit</option>
		                          <option value="Benard">Benard</option>
		                          <option value="Caroline">Caroline</option>
		                          <option value="Tanuj Gupta">Tanuj Gupta</option>
		                          <option value="Mini">Mini</option>
		                          <option value="Mir Ahmed">Mir Ahmed</option>
		                          <option value="Sayanti Bose">Sayanti Bose</option>
		                          <option value="Samrat Gaikwad">Samrat Gaikwad</option>
		                          <option value="Abdullah Wael">Abdullah Wael</option>
		                          <option value="Nisar Ahmed Khan">Nisar Ahmed Khan</option>
		                          <option value="Vivek Roy">Vivek Roy</option>
		                          <option value="Amin Abdullah">Amin Abdullah</option>
		                          <option value="Haya Alajlan">Haya Alajlan</option>
		                          <option value="Khurram Khowaja">Khurram Khowaja</option>
		                          <option value="Liz Berks">Liz Berks</option>
		                          <option value="Asif Khan">Asif Khan</option>
		                          <option value="Qusai Alhudhud">Qusai Alhudhud</option>
		                          <option value="Jones Joseph">Jones Joseph</option>
		                          <option value="Shamir Jha">Shamir Jha</option>
		                          <option value="Tarun Panda">Tarun Panda</option>
		                          <option value="Anindya Gupta">Anindya Gupta</option>
		                          <option value="Anirudh Anand">Anirudh Anand</option>
		                          <option value="Kannan Nadar">Kannan Nadar</option>
		                          <option value="Srutaban Bhowmick">Srutaban Bhowmick</option>
		                          <option value="Abu Talha">Abu Talha</option>
		                          <option value="Anjan Lahiri">Anjan Lahiri</option>
		                          <option value="Dola DasGupta Lahiri">Dola DasGupta Lahiri</option>
		                          <option value="Sana Syed">Sana Syed</option>
                      <option value="External Vendor">External Vendor</option>
                  </Form.Control>
                </Col>
              </Form.Group>
              
              {/* Project Name */}
              <Form.Group as={Row} controlId="formProjectName" className="mt-3">
                <Form.Label column sm="4">Project Name:</Form.Label>
                <Col sm="8">
                  <Form.Control type="text" placeholder="Enter Project Name" value={projectName} onChange={(e) => setProjectName(e.target.value)} />
                </Col>
              </Form.Group>

              {/* Job Number */}
              <Form.Group as={Row} controlId="formJobNumber" className="mt-3">
                <Form.Label column sm="4">Job Number:</Form.Label>
                <Col sm="8">
                  <Form.Control type="text" placeholder="Enter Job Number" value={jobNumber} onChange={(e) => setJobNumber(e.target.value)} />
                </Col>
              </Form.Group>

              {/* Year with Validation */}
              <Form.Group as={Row} controlId="formYear" className="mt-3">
                <Form.Label column sm="4">Year:</Form.Label>
                <Col sm="8">
                  <Form.Control
                    type="text"
                    placeholder="Enter Year"
                    value={year}
                    onChange={handleYearChange}
                    isInvalid={!!yearError}
                  />
                  <Form.Control.Feedback type="invalid">
                    {yearError}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              {/* Month */}
              <Form.Group as={Row} controlId="formMonth" className="mt-3">
                <Form.Label column sm="4">Month:</Form.Label>
                <Col sm="8">
                  <Form.Control as="select" value={month} onChange={(e) => setMonth(e.target.value)}>
                    <option value hidden>Select a Month</option>
                    <option value="January">January</option>
                    <option value="February">February</option>
                    <option value="March">March</option>
                    <option value="April">April</option>
                    <option value="May">May</option>
                    <option value="June">June</option>
                    <option value="July">July</option>
                    <option value="August">August</option>
                    <option value="September">September</option>
                    <option value="October">October</option>
                    <option value="November">November</option>
                    <option value="December">December</option>
                  </Form.Control>
                </Col>
              </Form.Group>

              {/* Scripting In-Charge */}
              <Form.Group as={Row} controlId="formScriptingInCharge" className="mt-3">
                <Form.Label column sm="4">Scripting In-Charge:</Form.Label>
                <Col sm="8">
                  <Form.Control as="select" value={inCharge} onChange={(e) => setInCharge(e.target.value)}>
                  <option value="" hidden>Select Scripting In-Charge</option>
		                         <option value="Ramamoorthy Karuppan">Ramamoorthy Karuppan</option>
		                         <option value="Naga Jyothi">Naga Jyothi</option>
		                         <option value="Anuradha">Anuradha</option>
		                         <option value="Saravana Kumar">Saravana Kumar</option>
		                         <option value="Varun">Varun</option>
		                         <option value="Raghu Ram">Raghu Ram</option>
		                         <option value="Sri Vendhan">Sri Vendhan</option>
		                         <option value="Satheeshkumar">Satheeshkumar</option>
		                         <option value="Karuppasamy">Karuppasamy</option>
		                         <option value="Joseph Arul Selvam">Joseph Arul Selvam</option>
		                         <option value="Mohanasundaram">Mohanasundaram</option>
		                         <option value="Reavanth">Reavanth</option>
		                         <option value="Ragul">Ragul</option>
		                         <option value="External Vendor">External Vendor</option>
                      <option value="Not Applicable">Not Applicable</option>
                  </Form.Control>
                </Col>
              </Form.Group>

              {/* DP In-Charge */}
              <Form.Group as={Row} controlId="formDPInCharge" className="mt-3">
                <Form.Label column sm="4">DP In-Charge:</Form.Label>
                <Col sm="8">
                  <Form.Control as="select" value={inCharge1} onChange={(e) => setInCharge1(e.target.value)}>
                  <option value="" hidden>Select DP In-Charge</option>
		                          <option value="Ramamoorthy Karuppan">Ramamoorthy Karuppan</option>
		                          <option value="Naga Jyothi">Naga Jyothi</option>
		                          <option value="Anuradha">Anuradha</option>
		                          <option value="Saravana Kumar">Saravana Kumar</option>
		                          <option value="Varun">Varun</option>
		                          <option value="Raghu Ram">Raghu Ram</option>
		                          <option value="Sri Vendhan">Sri Vendhan</option>
		                          <option value="Satheeshkumar">Satheeshkumar</option>
		                          <option value="Karuppasamy">Karuppasamy</option>
		                          <option value="Joseph Arul Selvam">Joseph Arul Selvam</option>
		                          <option value="Mohanasundaram">Mohanasundaram</option>
		                          <option value="Reavanth">Reavanth</option>
		                          <option value="Ragul">Ragul</option>
		                          <option value="External Vendor">External Vendor</option>
                      <option value="Not Applicable">Not Applicable</option>
                  </Form.Control>
                </Col>
              </Form.Group>

              {/* Submit Button */}
              <div className="d-flex justify-content-end mt-3">
                <Button variant="primary" onClick={handleFormSubmit}>
                  Proceed <IoIosArrowForward />
                </Button>
              </div>
            </Form>
          </div>
        </>
      )}

      {currentStep === 1 && surveyId && (
        <div className="text-center mt-5">
          <div className="p-3 rounded shadow-sm" style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)', display: 'inline-block' }}>
            <h5>Survey Link</h5>
            <div className="d-flex align-items-center justify-content-center mt-2">
              <Form.Control
                type="text"
                value={`${window.location.origin}/satisfactionsurvey/${surveyId}`}
                readOnly
                style={{ width: '300px', marginRight: '10px' }}
              />
              <Button variant="outline-secondary" onClick={handleCopyLink}>
                Copy Link
              </Button>
            </div>
            {showAlert1 && <Alert variant="success" className="mt-2">Link Copied!</Alert>}
          </div>
        </div>
      )}
    </Container>
  );
};

export default SurveyDetails;
