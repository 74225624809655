import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Card, Row, Col } from 'react-bootstrap';
import './SegView.css';

function SegView() {
  const location = useLocation();
  const { project } = location.state || {};

  console.log('Project data:', project);

  useEffect(() => {
    if (!project) {
      console.error('No project data available.');
    }
  }, [project]);

  if (!project) {
    return <div className="error-message">No project data available.</div>;
  }

  return (
    <div className="seg-view-container" style={{ width: '80%', margin: 'auto' }}>
      <div style={{ textAlign: 'center', marginBottom: '20px', marginTop: '20px' }}>
        <h3 className="project-title" style={{ fontFamily: "'Montserrat', sans-serif", color: '#2b5278' }}>
          Project: <span style={{ color: '#1d3557', fontWeight: 'bold' }}>{project.projectName}</span>
        </h3>
      </div>
      <Row className="g-4">
        {/* Project Details */}
        <Col md={6}>
          <Card className="custom-card">
            <Card.Header className="custom-card-header">Project Details</Card.Header>
            <Card.Body>
              <p><strong>Segment Name:</strong> {project.segmentName}</p>
              <p><strong>Unit:</strong> {project.unit}</p>
              <p><strong>Year:</strong> {project.year}</p>
              <p><strong>Month:</strong> {project.month}</p>
              <p><strong>Job Number:</strong> {project.jobNumber}</p>
              <p><strong>Segment Count:</strong> {project.segmentNumber}</p>
            </Card.Body>
          </Card>
        </Col>

        {/* Research and Analysis */}
        <Col md={6}>
          <Card className="custom-card">
            <Card.Header className="custom-card-header">Research and Analysis</Card.Header>
            <Card.Body>
              <p><strong>Research Group Head:</strong> {project.researchGroupHead}</p>
              <p><strong>Contact Person in Research:</strong> {project.contactPersonInResearch}</p>
              <p><strong>Contact Person in Scripting:</strong> {project.contactPersonForScripting}</p>
              <p><strong>Contact Person in DP:</strong> {project.contactPersonForDp}</p>
              <p><strong>Actual Sample Size:</strong> {project.actualSampleSize}</p>
              <p><strong>Achieved Sample Size:</strong> {project.achievedSampleSize}</p>
              <p><strong>Final Processed Samples:</strong> {project.finalProcessedSamples}</p>
            </Card.Body>
          </Card>
        </Col>

        {/* Timeline */}
        <Col md={6}>
          <Card className="custom-card">
            <Card.Header className="custom-card-header">Timeline</Card.Header>
            <Card.Body>
              <p><strong>Date of AWN:</strong> {project.dateOfAwn}</p>
              <p><strong>Date of Revised AWN:</strong> {project.dateOfRevisedAwn}</p>
              <p><strong>Final R-AWN Date:</strong> {project.finalRawnDate}</p>
              <p><strong>Questionnaire Received Date:</strong> {project.qnrReceivedDate}</p>
              <p><strong>AP Received Date:</strong> {project.apReceivedDate}</p>
              <p><strong>FW Start Date:</strong> {project.fwStartDate}</p>
              <p><strong>FW End Date:</strong> {project.fwEndDate}</p>
              <p><strong>Top Lines Date:</strong> {project.latestToplineDate}</p>
              <p><strong>Expected Date of Final Output:</strong> {project.expectedDateOfFinalOutput}</p>
              <p><strong>Actual Date of Final Output:</strong> {project.actualDateOfFinalOutput}</p>
            </Card.Body>
          </Card>
        </Col>

        {/* Platforms */}
        <Col md={6}>
          <Card className="custom-card">
            <Card.Header className="custom-card-header">Platforms</Card.Header>
            <Card.Body>
              <p><strong>Survey Platform:</strong> {project.surveyPlatform}</p>
              <p><strong>DP Platform:</strong> {project.dpPlatform}</p>
            </Card.Body>
          </Card>
        </Col>

        {/* Status */}
        <Col md={6}>
          <Card className="custom-card">
            <Card.Header className="custom-card-header">Status</Card.Header>
            <Card.Body>
              <p><strong>Status:</strong> {project.status}</p>
              <p><strong>Remarks:</strong> {project.remarks}</p>
              <p><strong>Project Classification:</strong> {project.projectClassification}</p>
              <p><strong>Backup Folder Path:</strong> {project.backupFolderPath}</p>
            </Card.Body>
          </Card>
        </Col>

        {/* Others */}
        <Col md={6}>
          <Card className="custom-card">
            <Card.Header className="custom-card-header">Others</Card.Header>
            <Card.Body>
              <p><strong>Advanced Analytics:</strong> {project.advancedAnalytics}</p>
              <p><strong>Number of Top Lines:</strong> {project.noOfTopLines}</p>
              <p><strong>OE Coding:</strong> {project.oeCoding}</p>
              <p><strong>OE Costing:</strong> {project.oeCosting}</p>
              <p><strong>Number of OE Responses:</strong> {project.numberOfResponses}</p>
              <p><strong>Norms Done:</strong> {project.normsDone}</p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default SegView;
