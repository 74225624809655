import React, { useEffect, useState } from "react";
import { Container, Tabs, Tab, Spinner, Alert, Card, Table, Form } from "react-bootstrap";
import axios from "axios";
import JTMonthWiseReport from "./JTMonthWiseReport";
import JTStartMonthSummary from "./JTStartMonthSummary";
import './JTSegmentSummary.css';

const JTSegmentSummary = () => {
  const [completedMonths, setCompletedMonths] = useState([]);

  const [contactPersonsDP, setContactPersonsDP] = useState([]);
  const [contactPersonsScripting, setContactPersonsScripting] = useState([]);
  const [selectedDP, setSelectedDP] = useState("");
  const [selectedScripting, setSelectedScripting] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [summaryDataDP, setSummaryDataDP] = useState(null);
  const [summaryDataScripting, setSummaryDataScripting] = useState(null);
  const [activeTab, setActiveTab] = useState("tab1");

  // Fetch data for dropdowns
  useEffect(() => {
    const fetchContactPersons = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem("Token");
    
        const [dpResponse, scriptingResponse, monthResponse] = await Promise.all([
          axios.get("https://4sightoperations.in/api/segments/fetch_dp_contacts/", {
            headers: { Authorization: `Token ${token}` },
          }),
          axios.get("https://4sightoperations.in/api/segments/fetch_scripting_contacts/", {
            headers: { Authorization: `Token ${token}` },
          }),
          axios.get("https://4sightoperations.in/api/segments/project_summary/", {
            headers: { Authorization: `Token ${token}` },
          }),
        ]);
    
        setContactPersonsDP(dpResponse.data || []);
        setContactPersonsScripting(scriptingResponse.data || []);
        const uniqueMonths = [
          ...new Set(monthResponse.data.map((item) => item.completed_month)),
        ].filter(Boolean);
        setCompletedMonths(uniqueMonths.sort());
      } catch (err) {
        setError("Failed to fetch dropdown data. Please try again.");
      } finally {
        setLoading(false);
      }
    };
    

    fetchContactPersons();
  }, []);

  // Fetch summary data
  const fetchSummaryData = async (name, type) => {
    try {
      setLoading(true);
      const token = localStorage.getItem("Token");
      const response = await axios.get("https://4sightoperations.in/api/segments/fetch_summary/", {
        params: { name, type_of_work: type },
        headers: { Authorization: `Token ${token}` },
      });

      if (type === "dp") {
        setSummaryDataDP(response.data);
      } else if (type === "scripting") {
        setSummaryDataScripting(response.data);
      }
    } catch (err) {
      setError("Failed to fetch summary data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Render binary attributes
  const renderBinaryAttributes = (summaryData) => {
    if (!summaryData?.binary_summary) return null;

    return (
      <Card className="mb-4">
        <Card.Header>Binary Attributes</Card.Header>
        <Card.Body>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Attribute</th>
                <th>Yes</th>
                <th>No</th>
                <th>Blanks</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(summaryData.binary_summary).map(([field, counts]) => (
                <tr key={field}>
                  <td>{field.replace(/_/g, " ").toUpperCase()}</td>
                  <td>{counts.Yes || 0}</td>
                  <td>{counts.No || 0}</td>
                  <td>{counts.Null || 0}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    );
  };

  // Render categorical counts
  const renderCategoricalCounts = (summaryData) => {
    if (!summaryData?.categorical_counts) return null;

    return (
      <Card className="mb-4">
        <Card.Header>Categorical Counts</Card.Header>
        <Card.Body>
          {Object.entries(summaryData.categorical_counts).map(([key, value]) => (
            <div key={key} className="mb-3">
              <h6 className="text-uppercase">{key.replace(/_/g, " ")}</h6>
              {typeof value === "object" ? (
                <Table bordered size="sm">
                  <thead>
                    <tr>
                      <th>Category</th>
                      <th>Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(value).map(([subKey, subValue]) => (
                      <tr key={subKey}>
                        <td>{subKey.replace(/_/g, " ")}</td>
                        <td>{subValue}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <p>{value || "N/A"}</p>
              )}
            </div>
          ))}
        </Card.Body>
      </Card>
    );
  };

  // Render numeric aggregations
  const renderNumericAggregations = (summaryData) => {
    if (!summaryData?.numeric_aggregations) return null;

    return (
      <Card className="mb-4">
        <Card.Header>Numeric Aggregations</Card.Header>
        <Card.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Metric</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(summaryData.numeric_aggregations).map(([key, value]) => (
                <tr key={key}>
                  <td>{key.replace(/_/g, " ").toUpperCase()}</td>
                  <td>{value || "N/A"}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    );
  };

  return (
    <Container className="mt-5">
  <Tabs
    activeKey={activeTab}
    onSelect={(tabKey) => setActiveTab(tabKey)}
    id="segment-summary-tabs"
    className="custom-tabs mb-4"
  >
    <Tab eventKey="tab1" title="Report by DP">
      <div className="tab-content-container">
        <h3 className="text-center">Summary by Contact Person for DP</h3>
        {loading && (
          <div className="text-center">
            <Spinner animation="border" />
            <p>Loading...</p>
          </div>
        )}
        {error && <Alert variant="danger">{error}</Alert>}
        {!loading && (
          <>
            <Form.Group controlId="dpSelect" className="mb-4">
              <Form.Label>Select Contact Person for DP</Form.Label>
              <Form.Control
                as="select"
                value={selectedDP}
                onChange={(e) => {
                  setSelectedDP(e.target.value);
                  fetchSummaryData(e.target.value, "dp");
                }}
              >
                <option value="">-- Select Contact Person --</option>
                {contactPersonsDP.map((person, index) => (
                  <option key={index} value={person}>
                    {person}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            {renderNumericAggregations(summaryDataDP)}
            {renderBinaryAttributes(summaryDataDP)}
            {renderCategoricalCounts(summaryDataDP)}
          </>
        )}
      </div>
    </Tab>
    <Tab eventKey="tab2" title="Report by Scripting">
      <div className="tab-content-container">
        <h3 className="text-center">Summary by Contact Person for Scripting</h3>
        {loading && (
          <div className="text-center">
            <Spinner animation="border" />
            <p>Loading...</p>
          </div>
        )}
        {error && <Alert variant="danger">{error}</Alert>}
        {!loading && (
          <>
            <Form.Group controlId="scriptingSelect" className="mb-4">
              <Form.Label>Select Contact Person for Scripting</Form.Label>
              <Form.Control
                as="select"
                value={selectedScripting}
                onChange={(e) => {
                  setSelectedScripting(e.target.value);
                  fetchSummaryData(e.target.value, "scripting");
                }}
              >
                <option value="">-- Select Contact Person --</option>
                {contactPersonsScripting.map((person, index) => (
                  <option key={index} value={person}>
                    {person}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            {renderNumericAggregations(summaryDataScripting)}
            {renderBinaryAttributes(summaryDataScripting)}
            {renderCategoricalCounts(summaryDataScripting)}
          </>
        )}
      </div>
    </Tab>
    <Tab eventKey="tab3" title="Report by Completed month">
      <div className="tab-content-container">
        <JTMonthWiseReport
          completedMonths={completedMonths}
          fetchSummaryData={fetchSummaryData}
          loading={loading}
          error={error}
        />
      </div>
    </Tab>
    <Tab eventKey="tab4" title="Report by Start Month">
      <div className="tab-content-container">
        <JTStartMonthSummary
          completedMonths={completedMonths}
          fetchSummaryData={fetchSummaryData}
          loading={loading}
          error={error}
        />
      </div>
    </Tab>
  </Tabs>
</Container>

  );
};

export default JTSegmentSummary;
